/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 */

import React, { useContext, useState, lazy, Suspense } from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, useHistory, useRouteMatch } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import { hot } from 'react-hot-loader/root'
const Login = lazy(() => import('components/Login'))
const ResetPasswordForm = lazy(() => import('components/ResetPasswordForm'))
const LockedOut = lazy(() => import('components/LockedOut'))
const Dashboard = lazy(() => import('components/Dashboard'))
const Notifications = lazy(() => import('components/Notifications/Index'))
const OrganizationsDashboard = lazy(() => import('components/Organizations/Dashboard'))
const Contacts = lazy(() => import('components/Contacts/Index/index'))
const Contacts567 = lazy(() => import('components/Contacts/Index/567/index'))
const ContactsShow = lazy(() => import('components/Contacts/index'))
const ContactConfirmation = lazy(() => import('components/ContactConfirmation/DataProvider'))
const DigitalConfirmation = lazy(() => import('components/ContactConfirmation/DigitalConfirmation'))
const FollowUps = lazy(() => import('components/FollowUps/Index'))
const FollowUpsShow = lazy(() => import('components/FollowUps/Show'))
const Appointments = lazy(() => import('components/Appointments/Index'))
const AppointmentsShow = lazy(() => import('components/Appointments/Show'))
const Campaigns = lazy(() => import('components/Campaigns/Index'))
const CampaignsShow = lazy(() => import('components/Campaigns/DataProvider'))
const CampaignAnalytics = lazy(() => import('components/Analytics'))
const ProspectixMap = lazy(() => import('components/Prospectix/MapContext'))
const AnalyticsReportsShow = lazy(() => import('components/AnalyticsReports/Show'))
const Events = lazy(() => import('components/Events/Index'))
const Lure = lazy(() => import('components/Lures/Show'))
const CommunicationCenter = lazy(() => import('components/CommunicationCenter'))
const ConfirmationCallsIndex = lazy(() => import('components/CallCenter/ConfirmationCalls/Index'))
const ConfirmationCallScript = lazy(() => import('components/CallCenter/ConfirmationCalls/Script'))
const ConfirmationCallCompletionCheck = lazy(() => import('components/CallCenter/ConfirmationCalls/CompletionCheck'))
const EmailTemplateEdit = lazy(() => import('components/EmailTemplates/Edit'))
const AutomationCampaign = lazy(() => import('components/AutomationCampaigns/Show'))
const Sequence = lazy(() => import('components/Sequence/Provider'))
const LandingPageEdit = lazy(() => import('components/LandingPages/Edit'))
const Users = lazy(() => import('components/Users/Index'))
const Preferences = lazy(() => import('components/Preferences'))
const Invoices = lazy(() => import('components/Invoices'))
const InvoicesShow = lazy(() => import('components/Invoices/Show'))
const GodDashboard = lazy(() => import('components/GodDashboard/Index'))
const GodCampaigns = lazy(() => import('components/Campaigns/God'))
const MerchantAccountsIndex = lazy(() => import('components/MerchantAccountsIndex'))
const PhoneNumbersShow = lazy(() => import('components/PhoneNumbers/Show'))
const CustomNumbersShow = lazy(() => import('components/CustomNumbers/Numbers/Show'))
const CcpaIndex = lazy(() => import('components/CcpaRequest/Index'))
const Logs = lazy(() => import('components/Logs/Show'))
const Unsubscribe = lazy(() => import('components/Unsubscribe'))

const NotFoundPage = lazy(() => import('containers/NotFoundPage/Loadable'))
const OAuth = lazy(() => import('components/OAuth/Loadable'))
const CalendlyOAuth = lazy(() => import('components/CalendlyOAuth'))
const WealthboxOAuth = lazy(() => import('components/OAuth/Integrations/Wealthbox'))
const NylasOAuth = lazy(() => import('components/NylasOAuth'))

const Sandbox = lazy(() => import('components/Sandbox'))

import Spinner from 'components/Spinner'

import BecomeOriginalUserBanner from 'components/BecomeOriginalUserBanner'
import PasswordlessBanner from 'components/LoginForm/Passwordless'
import NewVersionBanner from 'components/Banners/NewVersion'
import CompleteProfile from 'components/CompleteProfile'
import Sidebar, { sidebar_width } from 'components/Sidebar'
import NotificationToast from 'components/Notifications/Toast'
import ErrorBoundary from 'components/ErrorBoundary'
import NotSupportedBrowserModal from 'components/NotSupportedBrowserModal'
import EmailSubscriptionBanner from 'components/EmailSubscriptionBanner'

import { CurrentUserContext } from 'currentUserContext'

import Animate from 'react-move/Animate'
import { easeQuadOut } from 'd3-ease'

import { Global } from '@emotion/react'
import styled from '@emotion/styled'
import GlobalStyle, { COLORS } from 'global-styles'

export const HelmetContext = React.createContext({})

const Blank = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background-color: #fff;
`

export const Content = styled.div`
	min-height: 100%;

	margin-left: ${sidebar_width}px;
	padding: 13.5px 22.5px 45px 22.5px;
	position: relative;

	background-color: ${COLORS.backgroundGrey};

	transition: margin-left 250ms;

	> div > .container,
	> div > .container-fluid {
		padding: 0;
	}
`

const LoadingScreenContainer = styled.div`
	background-color: ${COLORS.backgroundGrey};
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
`

const RouteWithoutErrorContent = styled.div`
	height: calc(100vh - 58.5px);
`

const LoadingScreen = () => (
	<LoadingScreenContainer>
		<Spinner />
	</LoadingScreenContainer>
)

const RouteWithErrorBoundary = ({ component: Component, skip_animation, ...props }) => (
	<ErrorBoundary key={JSON.stringify(props.path)} message="Oops! Something went wrong.">
		<Route
			{...props}
			render={(router_props) => (
				<Animate
					show={Boolean(router_props.match)}
					start={() => ({
						opacity: 1e-6,
						y_offset: 40,
					})}
					enter={() => ({
						opacity: [1],
						y_offset: [1e-6],
						timing: {
							duration: skip_animation ? 0 : 750,
							ease: easeQuadOut,
						},
					})}
					leave={() => ({
						opacity: [1e-6],
						y_offset: [40],
						timing: {
							duration: 750,
							ease: easeQuadOut,
						},
					})}
				>
					{({ opacity, y_offset }) => (
						<RouteWithoutErrorContent
							style={{
								opacity,
								marginTop: `${y_offset}px`,
							}}
						>
							<Component {...router_props} />
						</RouteWithoutErrorContent>
					)}
				</Animate>
			)}
		/>
	</ErrorBoundary>
)

RouteWithErrorBoundary.propTypes = {
	computedMatch: PropTypes.objectOf(PropTypes.any),
}

function App() {
	const history = useHistory()
	const is_notification_path = useRouteMatch('/notifications/:id')
	const { current_user, loading } = useContext(CurrentUserContext)
	const [notification_count, setNotificationCount] = useState(0)

	if (window.location.pathname.startsWith('/rsvp')) {
		return (
			<Suspense fallback={<LoadingScreen />}>
				<Switch>
					<RouteWithErrorBoundary
						exact
						path={[
							'/rsvp/:attendee_record_id/:status?',
							'/rsvp/confirmation-status/:parent_id/:attendee_record_id',
						]}
						component={ContactConfirmation}
					/>
					<RouteWithErrorBoundary
						exact
						path="/rsvp-digital/:attendee_record_id/:status?"
						component={DigitalConfirmation}
					/>
				</Switch>
			</Suspense>
		)
	}

	if (window.location.pathname.startsWith('/email-settings')) {
		return (
			<Suspense fallback={<LoadingScreen />}>
				<RouteWithErrorBoundary exact path="/email-settings/:email_id" component={Unsubscribe} />
			</Suspense>
		)
	}

	if (!current_user.id && loading) {
		return <Blank />
	}

	if (!current_user.id && !loading) {
		if (window.location.pathname !== '/login' && window.location.pathname !== '/users/password/edit') {
			history.push('/login', {
				requested_location: `${window.location.pathname}${window.location.search}${window.location.hash}`,
			})
		}
		return (
			<Suspense fallback={<LoadingScreen />}>
				<Switch>
					<Route exact path="/login" component={Login} />
					<Route exect path="/users/password/edit" component={Login} />
				</Switch>
				<Global styles={GlobalStyle} />
			</Suspense>
		)
	}

	if (window.location.pathname === '/login' && current_user.id && !loading) {
		if (history.location.state) {
			if (history.location.state.requested_location.startsWith('/oauth/authorize')) {
				window.location = `${process.env.API_URL}${history.location.state.requested_location}`
			} else {
				history.push(history.location.state.requested_location || '/')
			}
		} else {
			history.push('/')
		}
	}

	if (current_user.reset_password_on_next_login) {
		return (
			<Suspense fallback={<LoadingScreen />}>
				<ResetPasswordForm />
				<BecomeOriginalUserBanner absolute />
			</Suspense>
		)
	}

	if (current_user.is_locked_out) {
		return (
			<Suspense fallback={<LoadingScreen />}>
				<LockedOut />
			</Suspense>
		)
	}

	if (is_notification_path) {
		window.location = process.env.API_URL + is_notification_path.url
		return null
	}

	if (window.location.pathname.includes('/oauth/')) {
		return (
			<Suspense fallback={<LoadingScreen />}>
				<RouteWithErrorBoundary exact path="/oauth/calendly" component={CalendlyOAuth} />
				<RouteWithErrorBoundary exact path="/oauth/wealthbox" component={WealthboxOAuth} />
				<RouteWithErrorBoundary exact path="/oauth/nylas" component={NylasOAuth} />
			</Suspense>
		)
	}

	return (
		<HelmetContext.Provider value={setNotificationCount}>
			<Helmet
				titleTemplate={notification_count > 0 ? `(${notification_count}) %s` : '%s'}
				defaultTitle={`${notification_count > 0 ? `(${notification_count}) ` : ''}LeadJig`}
			/>
			{!current_user.profile_complete && (
				<ErrorBoundary key="profile_complete">
					<CompleteProfile user_id={current_user.id} />
				</ErrorBoundary>
			)}
			<ErrorBoundary key="sidebar">
				<Sidebar user_id={current_user.id} />
			</ErrorBoundary>
			<ErrorBoundary key="NotificationToast">
				<NotificationToast user_id={current_user.id} />
			</ErrorBoundary>
			<NewVersionBanner />
			<PasswordlessBanner />
			<BecomeOriginalUserBanner />
			<NotSupportedBrowserModal user_id={current_user.id} />
			<EmailSubscriptionBanner user_id={current_user.id} />
			<Content id="content">
				<Suspense fallback={<LoadingScreen />}>
					{!current_user.is_confirmation_agent && (
						<Switch>
							<RouteWithErrorBoundary exact path="/" component={Dashboard} />
							<RouteWithErrorBoundary exact path="/users/dashboard/:user_id?" component={Dashboard} />
							<RouteWithErrorBoundary exact path="/notifications" component={Notifications} />
							<RouteWithErrorBoundary exact path="/organizations" component={OrganizationsDashboard} />
							<RouteWithErrorBoundary exact path="/contacts" component={Contacts} />
							<RouteWithErrorBoundary exact path="/contacts/567" component={Contacts567} />
							<RouteWithErrorBoundary exact path="/contacts/:id" component={ContactsShow} />
							<RouteWithErrorBoundary
								exact
								path="/contacts/:id/appointments/:appointment_id"
								component={ContactsShow}
							/>
							<RouteWithErrorBoundary
								exact
								path="/contacts/:id/follow_ups/:follow_up_id"
								component={ContactsShow}
							/>
							<RouteWithErrorBoundary exact path="/follow_ups" component={FollowUps} />
							<RouteWithErrorBoundary
								exact
								path="/follow_ups/:id"
								component={FollowUpsShow} /* redirects to contacts show */
							/>
							<RouteWithErrorBoundary exact path="/appointments" component={Appointments} />
							<RouteWithErrorBoundary
								exact
								path="/appointments/:id"
								component={AppointmentsShow} /* will redirect to contacts show */
							/>
							<RouteWithErrorBoundary exact path="/campaigns" component={Campaigns} />
							<RouteWithErrorBoundary exact path="/campaigns/:id/:page" component={CampaignAnalytics} />
							<RouteWithErrorBoundary
								exact
								path="/analytics_reports/:id"
								component={AnalyticsReportsShow}
							/>
							<RouteWithErrorBoundary
								exact
								path={['/campaigns/:id', '/campaigns/:id/events/:event_id', '/events/:event_id']}
								component={CampaignsShow}
							/>
							<RouteWithErrorBoundary exact path="/events" component={Events} />
							<RouteWithErrorBoundary exact path="/lures/:id" component={Lure} />
							{/* <RouteWithErrorBoundary exact path="/counts" component={CountShow} /> */}
							<RouteWithErrorBoundary exact path="/counts" component={ProspectixMap} />
							<RouteWithErrorBoundary
								exact
								path="/communication-center/:user_id?"
								component={CommunicationCenter}
							/>
							{/* <RouteWithErrorBoundary exact path="/content-center/:user_id?" component={ContentCenter} /> */}
							<RouteWithErrorBoundary exact path="/confirmation-center/:call_type/completion_check/:event_id" component={ConfirmationCallCompletionCheck} />
							<RouteWithErrorBoundary exact path="/confirmation-center/:call_type?" component={ConfirmationCallsIndex} />
							<RouteWithErrorBoundary exact path="/confirmation-center/:call_type/:event_id/:confirmation_call_id?" component={ConfirmationCallScript} />
							<RouteWithErrorBoundary exact path="/email_templates/:id/edit" component={EmailTemplateEdit} />
							<RouteWithErrorBoundary exact path="/automation_campaigns/:id" component={AutomationCampaign} />
							<RouteWithErrorBoundary exact path="/sequences/:id" component={Sequence} />
							<RouteWithErrorBoundary exact path="/landing_pages/:id/edit" component={LandingPageEdit} />
							<RouteWithErrorBoundary exact path="/users" component={Users} />
							<RouteWithErrorBoundary exact path="/users/preferences/:user_id?" component={Preferences} />
							<RouteWithErrorBoundary exact path="/invoices" component={Invoices} />
							<RouteWithErrorBoundary exact path="/invoices/:id" component={InvoicesShow} />
							<RouteWithErrorBoundary exact path="/phone_numbers/:id" component={PhoneNumbersShow} />
							<RouteWithErrorBoundary exact path="/custom_numbers/:id" component={CustomNumbersShow} />
							<RouteWithErrorBoundary exact path="/login" component={Login} />
							<RouteWithErrorBoundary exact path="/oauth" component={OAuth} />
							{current_user.is_god && (
								<RouteWithErrorBoundary exact path="/god" component={GodDashboard} />
							)}
							{current_user.is_god && (
								<RouteWithErrorBoundary exact path="/god/campaigns" component={GodCampaigns} />
							)}
							{current_user.is_consultant && (
								<RouteWithErrorBoundary exact path="/logs/:id" component={Logs} />
							)}
							{current_user.is_god && (
								<RouteWithErrorBoundary
									exact
									path="/merchant_accounts"
									component={MerchantAccountsIndex}
								/>
							)}
							{current_user.is_god && <RouteWithErrorBoundary exact path="/ccpa" component={CcpaIndex} />}
							<RouteWithErrorBoundary exact path="/sandbox" component={Sandbox} />
							<RouteWithErrorBoundary component={NotFoundPage} />
						</Switch>
					)}
					{current_user.is_confirmation_agent && (
						<Switch>
							<RouteWithErrorBoundary
								exact
								path="/communication-center/:user_id?"
								component={CommunicationCenter}
							/>
							{/* <RouteWithErrorBoundary exact path="/content-center/:user_id?" component={ContentCenter} /> */}
							<RouteWithErrorBoundary exact path="/confirmation-center/:call_type/completion_check/:event_id" component={ConfirmationCallCompletionCheck} />
							<RouteWithErrorBoundary exact path="/confirmation-center/:call_type?" component={ConfirmationCallsIndex} />
							<RouteWithErrorBoundary exact path="/confirmation-center/:call_type/:event_id/:confirmation_call_id?" component={ConfirmationCallScript} />
						</Switch>
					)}
				</Suspense>
			</Content>
			<Global styles={GlobalStyle} />
		</HelmetContext.Provider>
	)
}

export default hot(App)
