/**
 *
 * TabsBar
 *
 */

import React, { useState, useLayoutEffect, useRef, useEffect } from 'react'

// NPM Libraries
import styled from '@emotion/styled'

// Utils
import { COLORS } from 'global-styles'
import { useWindowSize } from 'hooks'

const UnorderedList = styled.ul`
	position: relative;
	display: block;
	background-color: transparent;
	list-style-type: none;
	padding: 0;
	@media (max-width: 430px) {
		left: -30px;
	}
`

const ListItem = styled.li`
	position: relative;
	display: block;
	float: left;
	height: 37px;
	text-align: center;
	padding: 0.5rem 0.75rem;
	margin: 0.25rem;
	z-index: 2;
	border-radius: 3.75px;

	a {
		color: inherit !important;
	}

	.btn-unstyled {
		color: initial;
		text-decoration: none;
	}

	&.active_nav_item {
		background-color: ${COLORS.success};
		color: #fff;
	}
`

const Indicator = styled.li`
	position: absolute;
	height: 37px;
	border-radius: 3.75px;
	background-color: ${COLORS.success};
	left: ${({ left }) => `${left}px`};
	right: ${({ right }) => `${right}px`};
	bottom: ${({ bottom }) => `${bottom}px`};
	will-change: left, right, bottom;
	transition: left 250ms ease-out ${({ left_delay }) => left_delay}ms,
		right 250ms ease-out ${({ right_delay }) => right_delay}ms, bottom 250ms ease-out;
`

function TabsBar({ children, index, ...nav_props }) {
	const tabs = React.Children.map(children, (child) => child).filter((child) => Boolean(child))

	const tab_indexes = tabs.map((tab, i) => tab.props.tab_index || tab.props.href || tab.props.to || i)

	let active_tab_index = Number.isNaN(parseInt(index, 10)) ? tab_indexes.findIndex((i) => index === i) : index
	active_tab_index = active_tab_index === -1 ? 0 : active_tab_index

	const [prev_index, setPrevIndex] = useState(-1)

	const elements = useRef([])

	const [left, setLeft] = useState(0)
	const [right, setRight] = useState(0)
	const [bottom, setBottom] = useState(0)
	const [left_delay, setLeftDelay] = useState(0)
	const [right_delay, setRightDelay] = useState(0)

	const { width } = useWindowSize()
	const [update_on_change, setUpdateOnChange] = useState(0)

	useLayoutEffect(() => {
		const active_element = elements.current[active_tab_index]

		if (active_element) {
			setLeft(active_element.offsetLeft)
			setRight(active_element.parentElement.offsetWidth - active_element.offsetLeft - active_element.offsetWidth)
			setBottom(
				active_element.parentElement.offsetHeight - active_element.offsetTop - active_element.offsetHeight,
			)
			if (active_tab_index - prev_index >= 0) {
				setLeftDelay(90)
				setRightDelay(0)
			} else {
				setRightDelay(90)
				setLeftDelay(0)
			}
			setPrevIndex(active_tab_index)
		}
	}, [JSON.stringify(tab_indexes), active_tab_index, width, update_on_change])

	useEffect(() => {
		setTimeout(() => setUpdateOnChange(1), 2000)
	}, [])

	return (
		<nav {...nav_props}>
			<UnorderedList data-test="tabs-bar">
				{React.Children.map(tabs, (child, i) => (
					<ListItem
						ref={(li) => {
							elements.current[i] = li
						}}
						className={active_tab_index === i ? 'active_nav_item' : ''}
					>
						{child}
					</ListItem>
				))}
				<Indicator
					left={left}
					right={right}
					bottom={bottom}
					left_delay={left_delay}
					right_delay={right_delay}
				/>
				<li style={{ clear: 'both' }} />
			</UnorderedList>
		</nav>
	)
}

TabsBar.propTypes = {}

export default TabsBar

// export default lifecycle({
// 	componentDidMount() {
// 		// This triggers an initial re-render because we rely on refs for
// 		// determining the indicators locations
// 		this.setState({ mounted: true })
// 	},
// })(TabsBar)
