/**
 *
 * Campaigns/Form/Sequences
 *
 */

import React from 'react'

// Graphql
import useQuery from 'components/UseQuery'
import { gql } from '@apollo/client'

// Components
import CircleImage from 'components/CircleImage'
import DropDownMenu from 'components/DropDownMenu'
import icon from 'components/Campaigns/Form/Template.svg'

import { usePermissionCheck } from 'hooks'

const GET_SEQUENCES = gql`
	query getSequences($user_id: ID!) {
		sequences(user_id: $user_id, campaign_type: "STANDARD") {
			id
			name
		}
	}
`

function Sequences({ onSelect, campaign: { id, user, sequence } }) {
	const can_use_feature_automation = usePermissionCheck(user && user.id, 'use', 'Feature', 'AUTOMATION')
	const can_automate_campaign = usePermissionCheck(user?.id, 'automate', 'Campaign', id)
	const can_use_automation = can_use_feature_automation || can_automate_campaign

	const {
		data: { sequences },
	} = useQuery(GET_SEQUENCES, {
		variables: {
			user_id: user && user.id,
		},
		channels: [`sequences_${user && user.id}`],
		default_data: {
			sequences: [],
		},
	})

	if (!can_use_automation || sequences?.length === 0) return null

	return (
		<div className="py-3 d-flex  align-items-center">
			<CircleImage size="4rem">
				<img src={icon} style={{ height: '2.6rem' }} alt="" />
			</CircleImage>
			<div className="ml-3">
				<span className="font-weight-bold">Sequence</span>
				<div>{sequence ? sequence.name : 'Use Default'}</div>
				{!user /* Mimic the ui when user has not been selected */ && (
					<div className="dropdown btn-link">
						<a className="dropdown-toggle" data-toggle="dropdown">
							Select Sequence
						</a>
						<ul className="dropdown-menu">
							<li className="dropdown-item clickable">Please select a campaign owner first.</li>
						</ul>
					</div>
				)}
				{user && (
					<DropDownMenu Button={<span>{sequence ? 'Change Sequence' : 'Select Sequence'}</span>}>
						<ul className="list-unstyled">
							<li>
								<button
									type="button"
									className="btn-unstyled w-100 text-left"
									onClick={() => onSelect(null)}
								>
									None
								</button>
							</li>
							{sequences.map((s) => (
								<li key={s.id}>
									<button
										type="button"
										className="btn-unstyled w-100 text-left"
										onClick={() => onSelect(s)}
									>
										{s.name}
									</button>
								</li>
							))}
						</ul>
					</DropDownMenu>
				)}
			</div>
		</div>
	)
}

Sequences.propTypes = {}

export default Sequences
