/* eslint-disable react/react-in-jsx-scope, @emotion/jsx-import */

/**
 *
 * Tabs
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
/** @jsx jsx */
// estlint-disable-next-line
import { css, jsx } from '@emotion/react'
import SwipeableViews from 'react-swipeable-views'

function Tabs({ index, virtualized, children, ...props }) {
	// Filter out all NULL tabs
	const tabs = React.Children.map(children, (child) => child).filter((child) => Boolean(child))

	// Get the indexes, defaulting to their position
	const tab_indexes = tabs.map((tab, i) => tab.props.tab_index || i)

	// Get the index of the current tab
	const tab_index = Number.isNaN(parseInt(index, 10)) ? tab_indexes.findIndex((i) => index === i) : index

	if (virtualized) {
		return tabs[tab_index] || tabs[0]
	}

	return (
		<SwipeableViews
			index={virtualized || tab_index === -1 ? 0 : tab_index}
			{...props}
			css={css`
				.react-swipeable-view-container > div {
					padding-left: 1px;
					padding-right: 1px;
				}
			`}
		>
			{virtualized ? tabs[tab_index] || tabs[0] : tabs}
		</SwipeableViews>
	)
}

Tabs.propTypes = {
	index: PropTypes.any,
	children: PropTypes.element,
	virtualized: PropTypes.bool,
}

Tabs.defaultProps = {
	virtualized: false,
}

export default Tabs
